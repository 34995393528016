export const CHANGE_ACTIVE_ROUTE = 'CHANGE_ACTIVE_ROUTE';
export const SET_EDITOR_IMG = 'SET_EDITOR_IMG';
export const SET_UNSAVED_BASE64 = 'SET_UNSAVED_BASE64';
export const CLEAR_UNSAVED_BASE64 = 'CLEAR_UNSAVED_BASE64';
export const SET_SCREENSHOTS = 'SET_SCREENSHOTS';
export const SET_SCREENSHOTS_FOLDER_ORDER = 'SET_SCREENSHOTS_FOLDER_ORDER';
export const SET_SCREENSHOTS_ITEM_ORDER = 'SET_SCREENSHOTS_ITEM_ORDER';
export const SET_EXPLORER_DATA = 'SET_EXPLORER_DATA';
export const UPDATE_EXPLORER_IMAGE_DATA = 'UPDATE_EXPLORER_IMAGE_DATA';
export const UPDATE_EXPLORER_FOLDER_DATA = 'UPDATE_EXPLORER_FOLDER_DATA';
export const SET_TRASH = 'SET_TRASH';
export const SET_LOADER_STATE = 'SET_LOADER_STATE';
export const SET_SHARED = 'SET_SHARED';
export const SET_CURRENT_TOOL = 'SET_CURRENT_TOOL';
export const SET_VIDEO_BLOBURLS = 'SET_VIDEO_BLOBURLS';
export const CLEAR_VIDEO_BLOBURLS = 'CLEAR_VIDEO_BLOBURLS';
export const SET_VIDEO_DURATION = 'SET_VIDEO_DURATION';
export const CLEAR_VIDEO_DURATION = 'CLEAR_VIDEO_DURATION';
export const SET_EDITOR_VIDEO = 'SET_EDITOR_VIDEO';
export const CLEAR_EDITOR_VIDEO = 'CLEAR_EDITOR_VIDEO';
export const SET_EXPLORER_DATA_VIDEOS = 'SET_EXPLORER_DATA_VIDEOS';
export const UPDATE_EXPLORER_VIDEO_DATA = 'UPDATE_EXPLORER_VIDEO_DATA';
export const SET_VIDEOS_FOLDER_ORDER = 'SET_VIDEOS_FOLDER_ORDER';
export const SET_VIDEOS_ITEM_ORDER = 'SET_VIDEOS_ITEM_ORDER';
export const SET_SHARED_VIDEOS = 'SET_SHARED_VIDEOS';
export const SET_LOADER_VIDEO_STATE = 'SET_LOADER_VIDEO_STATE';
export const SET_TRASH_VIDEOS = 'SET_TRASH_VIDEOS';
export const SET_FOLDER_TYPE = 'SET_FOLDER_TYPE';
export const UPDATE_EXPLORER_VIDEO_FOLDER_DATA =
  'UPDATE_EXPLORER_VIDEO_FOLDER_DATA';
export const RESET_EXPLORER_DATA_LOADER = 'RESET_EXPLORER_DATA_LOADER';
export const SET_FROM_EXISTED = 'SET_FROM_EXISTED';
export const CLEAR_EDITOR_IMG = 'CLEAR_EDITOR_IMG';
export const RESET_EXPLORER_DATA_LOADER_VIDEOS =
  'RESET_EXPLORER_DATA_LOADER_VIDEOS';
export const SET_EMAIL_IMAGE = 'SET_EMAIL_IMAGE';
export const SET_WIN_ID = 'SET_WIN_ID';
